import request from '../request'

const search = (keyword) => {
  return request.get('search', { keyword })
}

const header = () => {
  return request.get('mall/pc/header')
}
// 全局推荐商品
const recommends = (page) => {
  return request.get(`mall/recommend?limit=12&page=${page}`)
}



export default {
  search,
  header,
  recommends
}

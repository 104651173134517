import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import { userApi } from '@/apis'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const isLogin = ref(false)
    const token = ref('')
    const info = reactive({
      user_id: 0,
      username: '',
      nickname: '',
      avatar: '',
      birthday: '',
      gender: ''
    })
    const inviteCode = ref('')

    const login = (tokenString) => {
      token.value = tokenString
      isLogin.value = true

      updateUserInfo()
    }

    const updateUserInfo = () => {
      // 保存登录信息以后，在这里获取登录用户的信息
      userApi
        .userInfo()
        .then((res) => {
          info.user_id = res.info.user_id
          info.username = res.info.username
          info.nickname = res.info.nickname
          info.avatar = res.info.avatar
          info.birthday = res.info.birthday
          info.gender = res.info.gender
        })
        .catch((err) => {})
    }

    const logout = () => {
      token.value = ''
      isLogin.value = false
      info.user_id = 0
      info.username = ''
      info.nickname = ''
      info.avatar = ''
      info.birthday = ''
      info.gender = ''
      inviteCode.value = ''
    }

    return {
      token,
      isLogin,
      info,
      inviteCode,
      updateUserInfo,
      login,
      logout
    }
  },
  {
    persist: {
      paths: ['isLogin', 'token', 'info', 'inviteCode']
    }
  }
)

<!--
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-10 16:25:12
 * @LastEditors: Aimee
 * @FilePath: /Web/src/components/common/Search.vue
 * @Description: 搜索组件
-->
<template>
  <div class="search">
    <div class="options">分类 <img src="@/assets/icons/icon_down_arrow.png" alt="icon_down_arrow"></div>
    <a-divider type="vertical" />
    <input type="text" v-model="keyword" />
    <button @click="doSearch">搜索</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const keyword = ref('')
const router = useRouter()
const route = useRoute()

onMounted(() => {
  keyword.value = route.query.keyword
})

const doSearch = () => {
  if (!keyword.value || keyword.value.length === 0) {
    return
  }
  router.push({ name: 'Search', query: { keyword: keyword.value } })
}
</script>

<style lang="scss" scoped>
$height: 42px;

.search {
  margin-left: 100px;
  height: $height;
  border: 1px solid $red-weight;
  border-radius: 100px;
  flex: 1;
  @extend .flex-row ,.jc-start;

  .options {
    color: #999;
    line-height: $height;
    padding-left: 16px;
    @extend .flex-row;

    img {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    padding: 0 12px;
    font-size: $font-base;
    color: $color666;
  }

  button {
    background: linear-gradient(0deg, $red 0%, $red-light 100%);
    border-radius: 25px;
    border: none;
    color: $white;
    border-radius: 100px;
    width: 80px;
    font-weight: 400;
    font-size: $font-base + 1;
    color: #FFFFFF;
    height: $height;
    cursor: pointer;
  }
}
</style>

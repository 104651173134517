import LayoutView from '@/components/layouts/AuthLayout.vue'

export default [
  {
    path: '/auth',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'Auth',
        meta: {
          title: '用户登录'
        },
        component: () => import('@/views/auth/index.vue')
      },
      {
        path: 'register',
        name: 'AuthRegister',
        meta: {
          title: '用户注册'
        },
        component: () => import('@/views/auth/register.vue')
      },
      {
        path: 'forgot',
        name: 'AuthForgot',
        meta: {
          title: '忘记密码'
        },
        component: () => import('@/views/auth/forgot.vue')
      }
    ]
  }
]

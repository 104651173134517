<template>
  <Header />
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeList">
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <Footer />
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'

const includeList = ref(['Home'])
const route = useRoute()

watch(route, (to) => {
  if (to.meta?.keepAlive && includeList.value.indexOf(to.name) === -1) {
    includeList.value.push(to.name)
  }
})
</script>

/*
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-22 11:42:02
 * @LastEditors: Aimee
 * @FilePath: /Web/src/apis/interfaces/user.js
 * @Description: 个人中心接口
 */
import request from '../request'

const userInfo = (keyword) => {
  return request.get('user/info')
}

// pc个人中心其他接口
const pcUserOther = (keyword) => {
  return request.get('user/pc_user_other')
}

const upload = (data) => {
  const param = new FormData()
  param.append('file', data)
  return request.post('storage/upload', param)
}

const updateAvatar = (path) => {
  return request.put('user/info', {
    avatar: path
  })
}

const updateInfo = (data) => {
  return request.put('user/info', data)
}

// 我的收藏
const favorites = (data) => {
  return request.get('interaction/favorites', { params: data })
}
// 删除收藏
const delFavorites = (ids) => {
  return request.delete(`interaction/favorites/delete?ids=${ids}`)
}

// 浏览记录
const subscribes = (data) => {
  return request.get('interaction/subscribes',{params:data})
}

// 我的积分
const scoreLogs = (data) => {
  return request.get('payment/account/logs/score',{params:data})
}




export default {
  userInfo,
  pcUserOther,
  updateAvatar,
  upload,
  updateInfo,
  favorites,
  delFavorites,
  subscribes,
  scoreLogs
}

/*
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-21 17:24:39
 * @LastEditors: Aimee
 * @FilePath: /Web/src/main.js
 * @Description: main.js
 */
import './assets/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import Antd from 'ant-design-vue'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/reset.css'

// 自定义头像
import Avatar from '@/components/user/Avatar.vue'

import EmptyWidet from '@/components/common/EmptyWidet.vue'

const app = createApp(App)
const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(Antd)
app.component('Avatar', Avatar)
app.component('EmptyWidet', EmptyWidet)

app.mount('#app')

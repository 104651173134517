/*
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-20 14:16:12
 * @LastEditors: Aimee
 * @FilePath: /Web/src/router/routes/mall.js
 * @Description: mall 模块
 */
import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/mall',
    component: LayoutView,
    children: [
      {
        path: '/mall/category',
        name: 'CategoryList',
        meta: {
          title: '全局商品列表'
        },
        component: () => import('@/views/mall/index.vue')
      },
      {
        path: 'carts',
        name: 'MallCart',
        meta: {
          title: '购物车',
          requiresAuth: true
        },
        component: () => import('@/views/mall/cart.vue')
      },
      {
        path: 'goods',
        name: 'GoodsShow',
        meta: {
          title: '商品详情'
        },
        component: () => import('@/views/mall/goods/show.vue')
      },
      {
        path: 'ebook/:bookId(\\d+)',
        name: 'EBook',
        meta: {
          title: '电子书详情'
        },
        component: () => import('@/views/mall/goods/ebook.vue')
      },
      {
        path: 'buy/:goodsId(\\d+)',
        name: 'BuyNow',
        meta: {
          title: '立即购买',
          requiresAuth: true
        },
        component: () => import('@/views/mall/buy/now.vue')
      }
    ]
  }
]

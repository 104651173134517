<template>
  <div class="topper">
    <div class="container">
      <div class="left">
        欢迎您，
        <span v-if="auth.isLogin">
          <router-link :to="{ name: 'User' }">{{ auth.info.nickname }}</router-link>
        </span>
        <span v-else>
          请先<router-link :to="{ name: 'Auth' }"><span class="link">登录</span></router-link>或<router-link
            :to="{ name: 'AuthRegister' }"><span class="link">免费注册</span></router-link>
        </span>
      </div>
      <div class="right">
        <router-link :to="{ name: 'UserHistory' }">
          <div>我的足迹</div>
        </router-link>
        <router-link :to="{ name: 'UserOrder', query: {} }">
          <div>我的订单</div>
        </router-link>
        <router-link :to="{ name: 'MallCart' }">
          <div>
            <img class="category_all" src="@/assets/icons/icon_cart.png" alt="collection_cover">
            购物车
          </div>
        </router-link>
        <div>
          <img class="category_all" src="@/assets/icons/icon_collect.png" alt="collection_cover"> 收藏
        </div>
      </div>
    </div>
  </div>
  <!-- logo  搜索 购物车  头像 -->
  <div class="header container">
    <router-link class="logo" :to="{ name: 'Home' }">
      <img src="@/assets/images/logo.png" alt="LOGO" />
    </router-link>

    <!-- 搜索 -->
    <Search />

    <!-- 购物车 -->
    <div class="cart" @click="$router.push({ name: 'MallCart' })">
      <img src="@/assets/icons/icon_cart_red.png" alt="icon_down_arrow">购物车
    </div>

    <!-- 头像 -->
    <div class="round" v-if="!auth.isLogin">新华</div>
    <router-link :to="{ name: 'User' }" v-else>
      <Avatar :avatar="auth.info.avatar" :size='40' :fontSize="20" />
    </router-link>
  </div>

  <!-- 左侧全部分类 -->
  <div class="navigation container">
    <div class="categories">
      <div class="title" @click="showCategory = !showCategory">
        全部分类
        <img class="category_all" src="@/assets/icons/icon_category_all.png" alt="all">
      </div>
      <div class="category_list" v-show="showCategory">
        <div v-for="item in categories" :key="item.category_id">
          <router-link :to="`/mall/category?category_id=${item.category_id}`">
            <div class="categories-item">
              {{ item.name }}
              <img class="category_right" src="@/assets/icons/icon_category_right.png" alt="right">
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 顶部分类
      * index 首页
      * zone 专区
      * jiaofu 教辅
      * category_list 分类商品列表
      * big 大客户
      * book_store 新华
    -->
    <div class="navigations">
      <div v-for="(item, index) in navigations" :key="index">
        <div class="link">
          <router-link :to="{ path: item.paths.pc }">{{ item.title }}</router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRoute, useRouter } from 'vue-router'
import { homeApi } from '@/apis'
import Search from './Search.vue'

const router = useRouter()

const route = useRoute()
const showCategory = ref(false)
const currentRoutePath = ref('Home')

watch(route, (r) => {
  currentRoutePath.value = r.path
  console.log(r)
  if (r.name == 'Home') {
    showCategory.value = true
  } else {
    showCategory.value = false
  }
})

onMounted(() => {
  if (route.name == 'Home') {
    showCategory.value = true
  }
})
const auth = useAuthStore()
const navigations = ref([])
const categories = ref([])

homeApi.header().then((res) => {
  categories.value = res.categories
  navigations.value = res.navs
})
/**
 * index  首页
 * zone  专区
 * jiaofu  教辅
 * category_list  分类商品列表
 * big  大客户
 * book_store  新华
 */
// 自己 mock 数据
const tabs = ref([
  { name: '首页', route: 'Home', path: 'index', ext: {}, paths: { pc: '/' } },
  { name: '大客户专区', route: 'BigZone', path: 'big', ext: {}, paths: { pc: '/bigzone' } },
  { name: '新华专区', route: 'XinhuaBooks', path: 'book_store', ext: {}, paths: { pc: '/xinhuabooks' } },
  { name: '龙版专区', route: 'Zone', path: 'zone', ext: { zoneId: 1 }, paths: { pc: '/zone/1' } },
  { name: 'VIP专区', route: 'Zone', path: 'zone', ext: { zoneId: 2 }, paths: { pc: '/zone/2' } },
  { name: '工行专区', route: 'Zone', path: 'zone', ext: { zoneId: 3 }, paths: { pc: '/zone/3' } },
  { name: '高中教辅', route: 'Teaching', path: 'jiaofu', ext: { category_id: 12 }, paths: { pc: '/teaching/12' } },
  { name: '初中教辅', route: 'Teaching', path: 'jiaofu', ext: { category_id: 10 }, paths: { pc: '/teaching/10' } },
])
</script>

<style lang="scss" scoped>
$nav-width: 220px;

.navigation {
  @extend .flex-row;


  // 全部分类
  .categories {
    height: 60px;
    width: $nav-width;
    text-align: center;
    color: $white;
    z-index: 100;

    .title {
      line-height: 60px;
      background: linear-gradient(90deg, $red 0%, $red-light 100%);
      box-shadow: 0px 6px 16px 0px $red-weight;
      cursor: pointer;
      padding: 0px 18px 0 28px;
      font-size: $font-title;
      border-bottom: solid 1px rgba($color: #fff, $alpha: .03);
      @extend .flex-row,.jc-sb;

      .category_all {
        width: 20px;
        height: 20px;
      }
    }

    .title:hover {
      background: $red-weight;
    }

    .category_list {
      line-height: 40px;
      background: linear-gradient(90deg, $red 0%, $red-light 100%);
      height: $banner-height + 2;
      padding-bottom: 14px;
      font-size: $font-base + 3;
      padding-top: 2px;
      overflow-y: auto;

      .categories-item {
        padding: 10px 18px 10px 28px;
        border-bottom: solid 1px rgba($color: #fff, $alpha: .03);
        @extend .flex-row,.jc-sb;

        .category_right {
          width: 20px;
          height: 20px;
        }
      }

      .categories-item:hover {
        background: $red-weight;
      }

      a {
        color: $white;
      }
    }
  }

  .navigations {
    margin-left: 24px;
    flex: 1;
    @extend .flex-row, .jc-start;

    .link {
      font-size: $font-title;
      font-weight: bold;
      margin-right: 20px;
      min-width: 80px;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $red;
      }

      .router-link-active {
        color: $red;
        // font-size: $font-title;
        font-weight: bold;
      }

      a {
        color: #111111;
        font-size: $font-title;
        font-weight: bold;
      }

      &.active {
        a {
          color: $red;
        }
      }
    }
  }
}

.topper {
  background: $red;
  font-size: $font-base - 2;
  padding: 14px 0;
  box-sizing: border-box;

  .container {
    color: $white;
    @extend .flex-row,.jc-sb;

    .left {
      .link {
        padding: 0 4px;
      }

      a {
        color: $white;
      }
    }

    .right {
      @extend .flex-row;

      div {
        margin-left: 32px;
        cursor: pointer;
        color: $white;
        @extend .flex-row;

        img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }
    }
  }
}

// logo
.header {
  padding: 24px 0;
  gap: 12px;
  @extend .flex-row,.jc-start;

  .logo {
    width: 232px;
    height: 58px;
  }

  .cart {
    border: 1px solid $red;
    border-radius: 100px;
    height: 44px;
    line-height: 44px;
    padding: 0 16px;
    font-weight: 400;
    font-size: $font-title !important;
    cursor: pointer;
    @extend .flex-row;

    img {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
  }

  .round {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: $red;
    color: $white;
    text-align: center;
    line-height: 40px;
    font-size: $font-base - 4;
  }
}

.line {
  height: 2px;
  background: $red;
}
</style>

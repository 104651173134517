/*
 * @Author: Aimee~
 * @Date: 2024-04-17 09:43:13
 * @LastEditTime: 2024-04-22 17:35:02
 * @LastEditors: Aimee
 * @FilePath: /Web/src/router/routes/order.js
 * @Description: 我的订单
 */
import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/orders',
    component: LayoutView,
    meta: {
      keepAlive: false,
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'UserOrder',
        meta: {
          title: '我的订单'
        },
        component: () => import('@/views/user/order/index.vue')
      },
      {
        path: 'detail/:orderId',
        name: 'UserOrderDetail',
        meta: {
          title: '订单详情'
        },
        component: () => import('@/views/user/order/detail.vue')
      },
      {
        path: 'init',
        name: 'UserOrderInit',
        meta: {
          title: '确认订单'
        },
        component: () => import('@/views/user/order/init.vue')
      },
      {
        path: 'pay',
        name: 'UserOrderPay',
        meta: {
          title: '收银台'
        },
        component: () => import('@/views/user/order/pay.vue')
      },
      {
        path: 'logistics',
        name: 'UserOrderLogistics',
        meta: {
          title: '物流信息'
        },
        component: () => import('@/views/user/order/logistics.vue')
      },
      {
        path: 'review',
        name: 'UserOrderReview',
        meta: {
          title: '评论'
        },
        component: () => import('@/views/user/order/review.vue')
      },
      {
        path: 'refund',
        name: 'UserOrderRefund',
        meta: {
          title: '退货订单'
        },
        component: () => import('@/views/user/refund/index.vue')
      },
      {
        path: 'refund/detail/:orderId/:step',
        name: 'UserOrderRefundDetail',
        meta: {
          title: '退货详情'
        },
        component: () => import('@/views/user/refund/detail.vue')
      }
    ]
  }
]
